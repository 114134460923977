import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Hero from "./style";
import Video from "~components/VideoModal";
import Video1 from "../../../../assets/image/landing/tutoriales/video1.png";
import Video2 from "../../../../assets/image/landing/tutoriales/realizarFondeo.png";
import Video3 from "../../../../assets/image/landing/tutoriales/renewald.png";
import { Link } from "~components";

export default function HeroSection({ ...rest }) {

  return (
    <Hero backgroundColor="#fff" {...rest}>

      <Hero.Title as="h2" fontColor="">
        Pruebas Señuelo
      </Hero.Title>
      <div style={{ padding: "3rem" }}>
        <Container>
          <Row>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src="https://i.ytimg.com/vi/ly6i2N5JIwE/hqdefault.jpg?sqp=-oaymwFBCNACELwBSFryq4qpAzMIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB8AEB-AH-CYAC0AWKAgwIABABGF0gXShdMA8=&rs=AOn4CLB6vtxPiHjbc28qiY1K2329emWl4g"
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Add Contract"
                  ></img>
                  {/* Video Button */}
                  <Video id="JovkiS5hNPI" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    Prueba 1: Primer contacto
                  </Hero.Subtitle>
                  {/*<Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>*/}
                  <Hero.Text>
                    Para la primera prueba, denominada Primer Contacto, el objetivo es coordinar y ejecutar la entrega del artículo señuelo desde una sucursal hacia una ubicación predeterminada.
                  </Hero.Text>
                </Hero.Content>
            </Col>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src="https://i.ytimg.com/vi/ly6i2N5JIwE/hqdefault.jpg?sqp=-oaymwFBCNACELwBSFryq4qpAzMIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB8AEB-AH-CYAC0AWKAgwIABABGF0gXShdMA8=&rs=AOn4CLB6vtxPiHjbc28qiY1K2329emWl4g"
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Image Fondeo"
                  ></img>
                  {/* Video Button */}
                  <Video id="ly6i2N5JIwE" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    Prueba 2: Reparto Confianza
                  </Hero.Subtitle>
                  <Hero.Text>
                    En esta prueba, conocida como Reparto Confianza, se realiza una entrega más detallada con materiales específicos
                  </Hero.Text>
                </Hero.Content>
            </Col>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src="https://i.ytimg.com/vi/keCip7po4GE/hqdefault.jpg?sqp=-oaymwFBCNACELwBSFryq4qpAzMIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB8AEB-AH-CYAC0AWKAgwIABABGBUgOyh_MA8=&rs=AOn4CLDSFpWL0zz1UXiXiDQW6jK7bUGmcw"
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Image desempeñar"
                  ></img>
                  {/* Video Button */}
                  <Video id="keCip7po4GE" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    Prueba 3: Prueba de fuego
                  </Hero.Subtitle>
                  <Hero.Text>
                    La tercera prueba, denominada Prueba de Fuego, evalúa un proceso más complejo e incluye la entrega y recepción de documentos oficiales
                  </Hero.Text>
                </Hero.Content>
            </Col>
          </Row>
        </Container>
      </div>
    </Hero>
  );
}
